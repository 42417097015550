$draft-red: rgb(210, 42, 42);

$scrollbar-color: rgba(172, 133, 47, 0.4);
$link-color: rgb(240, 214, 167);
$link-hover-color: rgb(251, 224, 174);
$tag-color: rgb(113, 89, 68);
$navigation-color: rgb(113, 89, 68);

$dark-bg-color: rgb(32, 30, 25);
$dark-body-color: rgba(255, 255, 226, 0.8);
$dark-body-bg-color: rgb(36, 32, 25);
$dark-text-color: rgb(255, 255, 218);
$dark-border-color: rgb(110, 100, 83);
$dark-link-color: rgb(240, 214, 167);
$dark-hover-link-color: rgb(251, 224, 174);
$dark-table-border-color: rgb(82, 73, 57);
$dark-smalltitle-color: rgb(254, 226, 176);
$dark-table-alternate-bg-color: rgb(40, 35, 27);
$dark-table-header-footer-bg-color: rgb(34, 30, 21);
$dark-sidebar-bg-color: rgb(40, 35, 27);
$dark-toc-bg-color: rgb(40, 35, 27);
$dark-pre-color: rgba(255, 255, 226, 0.9);
$dark-pre-bg-color: rgb(40, 40, 40); // gruvbox 
$dark-not-pre-code-bg-color: rgb(62, 54, 41);
$dark-bigtitle-color: rgb(219, 195, 152);
$dark-footer-bg-color: rgba(32, 25, 18, 0.7);
$dark-h1-color: rgba(255, 255, 226, 0.85);
$dark-quote-color: rgba(255, 255, 226, 0.85);
$dark-title-link-color: rgb(231, 205, 160);
$dark-horizontal-bar-color: rgb(77, 68, 54);
$dark-attribution-color: rgba(255, 255, 216, 0.6);
$dark-default-border-color: rgb(81, 73, 56);
$dark-admonition-icon-tip-shadow-color: rgba(179, 160, 119, 0.8);
$dark-admonition-icon-tip-color: rgb(253, 233, 154);
$dark-admonition-icon-note-color: rgb(175, 202, 243);
$dark-admonition-icon-important-color: rgb(213, 41, 41);
$dark-admonition-icon-warning-color: rgb(191, 105, 0);
$dark-admonition-icon-caution-color: rgb(191, 52, 0);
$dark-admonition-icon-important-bg-color: rgb(255, 200, 200);
$dark-admonition-icon-important-border-color: rgb(255, 103, 103);
$dark-admonition-icon-warning-bg-color: rgb(249, 220, 200);
$dark-admonition-icon-warning-border-color: rgb(243, 170, 121);
$dark-admonition-icon-caution-bg-color: rgb(247, 229, 206);
$dark-admonition-icon-caution-border-color: rgb(250, 181, 94);
$dark-exampleblock-bg-color: rgb(60, 53, 39);
$dark-exampleblock-border-color: rgb(83, 74, 58);
$dark-exampleblock-shadow-color: rgb(48, 43, 34) 0 1px 4px;
$dark-sidebar-border-color: rgb(88, 78, 61);
$dark-toc-border-color: rgb(78, 69, 54);
$dark-input-bg-color: #625c36;
$dark-blockquote-bg-color: rgb(60, 48, 33);
$dark-blockquote-border-color: rgb(145, 114, 75);
$dark-pre-border-color: rgb(88, 78, 61);


$block-switch-border-color: rgb(88, 78, 61);
$block-switch-not-selected-tab-color: rgb(254, 226, 176);
$block-switch-not-selected-tab-bg-color: rgb(40, 35, 27);
$block-switch-selected-tab-color: rgb(255, 224, 174);
$block-switch-selected-tab-bg-color: rgb(60, 48, 33);
$block-switch-selected-tab-bg-gradient-color: rgb(244, 191, 117);
