// mobile ~320..480px
// 320px ~ 16rem/16px ~ 18.8235/17px ~ 17.7778/18px
// 480px ~ 30rem/16px ~ 28.2353rem/17px ~ 26.6667rem/18px
@media (min-width: 320px) and (max-width: 767px) {
  .post.adoc {
    .admonitionblock {
      > table {
        td.content {
          border-left: none;
          border-right: none;
        }

        td.icon {
          border-bottom: 2px solid $dark-default-border-color;
        }
      }

      &.important {
        > table {
          td.content {
            border-left: none;
            border-right: none;
          }

          td.icon {
            border-bottom: 2px solid $dark-admonition-icon-important-border-color;
          }
        }
      }

      &.warning {
        > table {
          td.content {
            border-left: none;
            border-right: none;
          }

          td.icon {
            border-bottom: 2px solid $dark-admonition-icon-warning-border-color;
          }
        }
      }

      &.caution {
        > table {
          td.content {
            border-left: none;
            border-right: none;
          }

          td.icon {
            border-bottom: 2px solid $dark-admonition-icon-caution-border-color;
          }
        }
      }
    }
  }
}