$dark-bg-color: rgb(32, 30, 25) !default;
$dark-body-color: rgba(255, 255, 226, 0.8) !default;
$dark-body-bg-color: rgb(36, 32, 25) !default;
$dark-text-color: #ffffda !default;
$dark-border-color: #6e6453 !default;
$dark-link-color: rgb(240, 214, 167) !default;
$dark-hover-link-color: rgb(253, 205, 143) !default;
$dark-table-border-color: rgb(82, 73, 57) !default;
$dark-smalltitle-color: rgb(254, 226, 176) !default;
$dark-table-alternate-bg-color: rgb(40, 35, 27) !default;
$dark-table-header-footer-bg-color: rgb(34, 30, 21) !default;
$dark-sidebar-bg-color: rgb(40, 35, 27) !default;
$dark-toc-bg-color: rgb(40, 35, 27) !default;
$dark-pre-color: rgba(255, 255, 226, 0.9) !default;
$dark-pre-bg-color: rgb(40, 35, 27) !default;
$dark-not-pre-code-bg-color: rgb(62, 54, 41) !default;
$dark-bigtitle-color: rgb(219, 195, 152) !default;
$dark-footer-bg-color: rgba(32, 25, 18, 0.7) !default;
$dark-h1-color: rgba(255, 255, 226, 0.85) !default;
$dark-quote-color: rgba(255, 255, 226, 0.85) !default;
$dark-title-link-color: rgb(231, 205, 160) !default;
$dark-horizontal-bar-color: rgb(77, 68, 54) !default;
$dark-attribution-color: rgba(255, 255, 216, 0.6) !default;
$dark-default-border-color: rgb(81, 73, 56) !default;
$dark-admonition-icon-tip-shadow-color: rgba(179, 160, 119, 0.8) !default;
$dark-admonition-icon-tip-color: rgb(253, 233, 154) !default;
$dark-admonition-icon-note-color: rgb(175, 202, 243) !default;
$dark-admonition-icon-important-color: rgb(213, 41, 41) !default;
$dark-admonition-icon-warning-color: rgb(191, 105, 0) !default;
$dark-admonition-icon-caution-color: rgb(191, 52, 0) !default;
$dark-admonition-icon-important-bg-color: #ffc8c8 !default;
$dark-admonition-icon-important-border-color: #ff6767 !default;
$dark-admonition-icon-warning-bg-color: #f9dcc8 !default;
$dark-admonition-icon-warning-border-color: #f3aa79 !default;
$dark-admonition-icon-caution-bg-color: #f7e5ce !default;
$dark-admonition-icon-caution-border-color: #fab55e !default;
$dark-conum-color: rgb(32, 30, 25) !default;
$dark-conum-bg-color: rgb(197, 153, 83) !default;
$dark-exampleblock-bg-color: rgb(60, 53, 39) !default;
$dark-exampleblock-border-color: rgb(83, 74, 58) !default;
$dark-exampleblock-shadow-color: rgb(48, 43, 34) 0 1px 4px !default;
$dark-sidebar-border-color: rgb(88, 78, 61) !default;
$dark-toc-border-color: rgb(78, 69, 54) !default;
$dark-input-bg-color: #625c36 !default;
$dark-blockquote-bg-color: rgb(60, 48, 33) !default;
$dark-blockquote-border-color: rgb(145, 114, 75) !default;
$dark-pre-border-color: rgb(88, 78, 61) !default;

// seems like asciidark CSS coloring suffice for dark theme, hence we don't
// target specifically `adoc` class, but `md` too.

.post.md, .post.adoc {
  #header {
    > h1:first-child {
      color: $dark-h1-color;
    }

    .details {
      border-bottom-color: $dark-default-border-color;
      color: rgba(255, 255, 226, 0.6);
    }
  }

  #footer {
    background-color: $dark-footer-bg-color;
  }

  h1, h2, h3, #toctitle, .sidebarblock > .content > .title, h4, h5, h6 {
    color: $dark-bigtitle-color;
  }

  #toctitle {
    color: $dark-smalltitle-color;
  }

  #toc.toc2 {
    background-color: $dark-toc-bg-color;
    border-right-color: $dark-toc-border-color; // left ?
    border-left-color: $dark-toc-border-color; // left ?
  }

  #content h1 > a.link:hover, h2 > a.link:hover, h3 > a.link:hover, #toctitle > a.link:hover, .sidebarblock > .content > .title > a.link:hover, h4 > a.link:hover, h5 > a.link:hover, h6 > a.link:hover {
    color: $dark-title-link-color;
  }

  #content h1 > a.link, h2 > a.link, h3 > a.link, #toctitle > a.link, .sidebarblock > .content > .title > a.link, h4 > a.link, h5 > a.link, h6 > a.link {
    color: $dark-bigtitle-color;
    text-decoration-color: initial;
    text-decoration: none;
  }

  //.paragraph.lead > p, #preamble > .sectionbody > [class="paragraph"]:first-of-type p {
  //  color: rgba(255, 255, 216, 0.85);
  //}

  hr {
    border-color: $dark-horizontal-bar-color;
  }

  //.sect1 + .sect1 {
  //  border-top-color: rgb(73, 65, 52);
  //}

  .subheader, .admonitionblock td.content > .title, .audioblock > .title, .exampleblock > .title, .imageblock > .title, .listingblock > .title, .literalblock > .title, .stemblock > .title, .openblock > .title, .paragraph > .title, .quoteblock > .title, table.tableblock > .title, .verseblock > .title, .videoblock > .title, .dlist > .title, .olist > .title, .ulist > .title, .qlist > .title, .hdlist > .title, .subheader, .admonitionblock td.content > .title, .audioblock > .title, .exampleblock > .title, .imageblock > .title, .listingblock > .title, .literalblock > .title, .stemblock > .title, .openblock > .title, .paragraph > .title, .quoteblock > .title, table.tableblock > .title, .verseblock > .title, .videoblock > .title, .dlist > .title, .olist > .title, .ulist > .title, .qlist > .title, .hdlist > .title {
    color: $dark-smalltitle-color;
  }

  table {
    background-image: initial;
    background-color: $dark-body-bg-color;
    border-color: $dark-table-border-color;

    tr {
      th, td {
        color: $dark-body-color;
      }
    }

    tbody tr:nth-child(2n+1) td {
      background-color: $dark-table-alternate-bg-color;
    }

    thead tr {
      th, td {
        color: $dark-body-color;
      }
    }

    tfoot tr {
      th, td {
        color: $dark-body-color;
      }
    }

    thead, tfoot {
      background-image: initial;
      background-color: $dark-table-header-footer-bg-color;
    }

    &.tableblock {
      border-top-color: $dark-table-border-color;
      border-right-color: $dark-table-border-color;
      border-bottom-color: $dark-table-border-color;
      border-left-color: $dark-table-border-color;
    }
  }

  th.tableblock, td.tableblock {
    border-top-color: $dark-table-border-color;
    border-right-color: $dark-table-border-color;
    border-bottom-color: $dark-table-border-color;
    border-left-color: $dark-table-border-color;
  }

  table tr {
    &.even, &.alt, &:nth-of-type(2n) {
      background-image: initial;
      background-color: $dark-table-alternate-bg-color;
    }
  }

  .colist > table tbody > tr {
    background-color: $dark-body-bg-color;

    &:nth-child(2n+1) td {
      background-color: $dark-body-bg-color;
    }
  }

  a {
    color: $dark-link-color;

    &:hover, &:focus {
      color: $dark-hover-link-color;
    }

    line-height: inherit;
    background: transparent initial;
  }

  :not(pre) {
    &:not([class^=L]) {
      > code {
        background: $dark-not-pre-code-bg-color;
      }
    }
  }

  code {
    color: $dark-pre-color;
  }

  pre {
    color: $dark-pre-color;
    border-color: $dark-pre-border-color;

    > code {
      color: $dark-pre-color;
    }
  }

  .listingblock {
    & > .content > pre:not(.highlight) {
      background-color: $dark-pre-bg-color;
    }

    pre {
      &[class^="highlight "], &.CodeRay, &.prettyprint {
        background-image: initial;
        background-color: $dark-pre-bg-color;
      }
    }

    pre {
      background-image: initial;
      background-color: $dark-pre-bg-color;
    }

    pre {
      &:not(.highlight), &[class="highlight"], &[class^="highlight "], &.CodeRay, &.prettyprint {
        background-image: initial;
        background-color: $dark-pre-bg-color;
      }
    }
  }

  .literalblock pre {
    background-color: $dark-pre-bg-color;
  }

  .quoteblock {
    blockquote {
      &::before {
        //color: rgb(122, 37, 24);
        color: $dark-smalltitle-color;
      }

      color: $dark-quote-color;
      background-color: $dark-blockquote-bg-color;
      border-left-color: $dark-blockquote-border-color;;
    }

    p {
      color: $dark-quote-color;
    }

    .attribution cite {
      color: $dark-attribution-color;
    }
  }

  .verseblock .attribution cite {
    color: $dark-attribution-color;
  }

  .admonitionblock {
    > table {
      td.content {
        border-left-color: $dark-default-border-color;
        color: $dark-body-color;
      }

      td.icon {
        .icon-tip::before {
          text-shadow: $dark-admonition-icon-tip-shadow-color 1px 1px 2px;
          color: $dark-admonition-icon-tip-color;
        }

        .icon-note::before {
          color: $dark-admonition-icon-note-color;
        }

        .icon-important::before {
          color: $dark-admonition-icon-important-color;
        }

        .icon-warning::before {
          color: $dark-admonition-icon-warning-color;
        }

        .icon-caution::before {
          color: $dark-admonition-icon-caution-color;
        }
      }
    }

    // fix hyde-hyde integration
    &.important {
      border-left: none;
      border-right: none;
      background-color: $dark-admonition-icon-important-bg-color;

      > table td.content {
        background-color: rgb(72, 0, 0);
        border-left: 2px solid $dark-admonition-icon-important-border-color;
      }
    }

    &.warning {
      border-left: none;
      background-color: $dark-admonition-icon-warning-bg-color;
      border-right: none;

      > table td.content {
        background-color: rgb(76, 31, 0);
        border-left: 2px solid $dark-admonition-icon-warning-border-color;
      }
    }

    &.caution {
      border-left: none;
      border-right: none;
      background-color: $dark-admonition-icon-caution-bg-color;

      > table td.content {
        background-color: rgba(49, 28, 0, 0.95);
        border-left: 2px solid $dark-admonition-icon-caution-border-color;
      }
    }

  }

  .conum[data-value] {
    color: $dark-conum-color !important;
    background: $dark-conum-bg-color;
  }

  .exampleblock > .content {
    background-color: $dark-exampleblock-bg-color;
    border-color: $dark-exampleblock-border-color;
    box-shadow: $dark-exampleblock-shadow-color;
  }

  .sidebarblock {
    border-top-color: $dark-sidebar-border-color;
    border-right-color: $dark-sidebar-border-color;
    border-bottom-color: $dark-sidebar-border-color;
    border-left-color: $dark-sidebar-border-color;
    background-image: initial;
    background-color: $dark-sidebar-bg-color;
  }

  .hdlist > table tbody tr {
    background-color: $dark-body-bg-color;

    &:nth-child(2n+1) td {
      background-color: $dark-body-bg-color;
    }
  }


  /* image display */
  .content img, object, svg {
    background-color: rgb(183, 158, 116);
  }

  .content img, .content svg {
    opacity: .65;
    transition: opacity .5s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }

  /*colors*/
  .aqua {
    color: rgb(185, 230, 202);
  }

  .aqua-background {
    background-color: rgb(116, 178, 158);
  }

  .black {
    color: rgb(12, 12, 10);
  }

  .black-background {
    background-color: rgb(30, 28, 25);
  }

  .blue {
    color: rgb(150, 161, 180);
  }

  .blue-background {
    background-color: rgb(39, 36, 117);
  }

  .fuchsia {
    color: rgb(222, 145, 186);
  }

  .fuchsia-background {
    background-color: rgb(156, 75, 134);
  }

  .gray {
    color: rgb(134, 127, 114);
  }

  .gray-background {
    background-color: rgb(90, 85, 75);
  }

  .green {
    color: rgb(82, 146, 50);
  }

  .green-background {
    background-color: rgb(73, 111, 54);
  }

  .lime {
    color: rgb(172, 217, 132);
  }

  .lime-background {
    background-color: rgb(99, 163, 73);
  }

  .maroon {
    color: rgb(169, 87, 38);
  }

  .maroon-background {
    background-color: rgb(97, 48, 40);
  }

  .navy {
    color: rgb(6, 11, 74);
  }

  .navy-background {
    background-color: rgb(41, 38, 77);
  }

  .olive {
    color: rgb(160, 147, 60);
  }

  .olive-background {
    background-color: rgb(139, 130, 69);
  }

  .purple {
    color: rgb(107, 57, 92);
  }

  .purple-background {
    background-color: rgb(107, 57, 92);
  }

  .red {
    color: rgb(223, 93, 93);
  }

  .red-background {
    background-color: rgb(139, 60, 49);
  }

  .silver {
    color: rgb(93, 82, 61);
  }

  .silver-background {
    background-color: rgb(73, 69, 61);
  }

  .teal {
    color: rgb(115, 142, 132);
  }

  .teal-background {
    background-color: rgb(83, 120, 106);
  }

  .white {
    color: rgb(255, 239, 214);
  }

  .white-background {
    background-color: rgb(185, 177, 164);
  }

  .yellow {
    color: rgb(255, 243, 152);
  }

  .yellow-background {
    background-color: rgb(208, 195, 97);
  }

}