$dark-gist-bg-color: rgb(36, 32, 25) !default;
$dark-gist-color: rgba(255, 255, 226, 0.8) !default;
$dark-gist-border-color: rgb(110, 100, 83) !default;

body .gist {
  .gist-data {
    background-color: $dark-gist-bg-color;
    border-bottom: none;
  }

  .gist-file {
    border-color: $dark-gist-border-color;
  }

  .gist-meta {
    color: $dark-gist-color;
    background-color: $dark-gist-bg-color;
  }

  .highlight {
    background-color: transparent;
  }

  td {
    border: none;
  }


  /*! GitHub: Idle Fingers */
  // https://github.com/StylishThemes/GitHub-Dark/blob/master/themes/src/github/idle-fingers.css
  .pl-c, .pl-c span {
    color: #bc9458 !important;
    font-style: italic !important;
  }

  /* comment */
  .pl-c1 {
    color: #6c99bb !important;
  }

  /* constant */
  .pl-cce {
    color: #cc7833 !important;
  }

  /* constant.character.escape */
  .pl-cn {
    color: #cc7833 !important;
  }

  /* constant.numeric */
  .pl-coc {
    color: #cc7833 !important;
  }

  /* constant.other.color */
  .pl-cos {
    color: #a5c261 !important;
  }

  /* constant.other.symbol */
  .pl-e {
    color: #ffc66d !important;
  }

  /* entity */
  .pl-ef {
    color: #b83426 !important;
  }

  /* entity.function */
  .pl-en {
    color: #ffc66d !important;
  }

  /* entity.name */
  .pl-enc {
    color: #cc7833 !important;
  }

  /* entity.name.class */
  .pl-enf {
    color: #b83426 !important;
  }

  /* entity.name.function */
  .pl-enm {
    color: #b83426 !important;
  }

  /* entity.name.method-name */
  .pl-ens {
    color: #cc7833 !important;
  }

  /* entity.name.section */
  .pl-ent {
    color: #ffe5bb !important;
  }

  /* entity.name.tag */
  .pl-entc {
    color: #b83426 !important;
  }

  /* entity.name.type.class */
  .pl-enti {
    color: #b83426 !important;
    font-weight: bold !important;
  }

  /* entity.name.type.instance */
  .pl-entm {
    color: #b83426 !important;
  }

  /* entity.name.type.module */
  .pl-eoa {
    color: #ffe5bb !important;
  }

  /* entity.other.attribute-name */
  .pl-eoac {
    color: #b83426 !important;
  }

  /* entity.other.attribute-name.class */
  .pl-eoac .pl-pde {
    color: #b83426 !important;
  }

  /* punctuation.definition.entity */
  .pl-eoai {
    color: #ffe5bb !important;
  }

  /* entity.other.attribute-name.id */
  .pl-eoai .pl-pde {
    color: #ffe5bb !important;
  }

  /* punctuation.definition.entity */
  .pl-eoi {
    color: #b83426 !important;
  }

  /* entity.other.inherited-class */
  .pl-k {
    color: #cc7833 !important;
  }

  /* keyword */
  .pl-ko {
    color: #ffe5bb !important;
  }

  /* keyword.operator */
  .pl-kolp {
    color: #ffe5bb !important;
  }

  /* keyword.operator.logical.python */
  .pl-kos {
    color: #cc7833 !important;
  }

  /* keyword.other.special-method */
  .pl-kou {
    color: #cc7833 !important;
  }

  /* keyword.other.unit */
  .pl-mai .pl-sf {
    color: #b83426 !important;
  }

  /* support.function */
  .pl-mb {
    color: #a5c261 !important;
    font-weight: bold !important;
  }

  /* markup.bold */
  .pl-mc {
    color: #ffe5bb !important;
  }

  /* markup.changed */
  .pl-mh .pl-pdh {
    color: #cc7833 !important;
  }

  /* markup.heading punctuation.definition.heading */
  .pl-mi {
    color: #ffe5bb !important;
    font-style: italic !important;
  }

  /* markup.italic */
  .pl-ml {
    color: #a5c261 !important;
  }

  /* markup.list */
  .pl-mm {
    color: #b83426 !important;
  }

  /* meta.module-reference */
  .pl-mp {
    color: #cc7833 !important;
  }

  /* meta.property-name */
  .pl-mp1 .pl-sf {
    color: #cc7833 !important;
  }

  /* meta.property-value support.function */
  .pl-mq {
    color: #cc7833 !important;
  }

  /* markup.quote */
  .pl-mr {
    color: #ffe5bb !important;
  }

  /* meta.require */
  .pl-ms {
    color: #ffe5bb !important;
  }

  /* meta.selector */
  .pl-pdb {
    color: #a5c261 !important;
    font-weight: bold !important;
  }

  /* punctuation.definition.bold */
  .pl-pdc {
    color: #bc9458 !important;
    font-style: italic !important;
  }

  /* punctuation.definition.comment */
  .pl-pdc1 {
    color: #6c99bb !important;
  }

  /* punctuation.definition.constant */
  .pl-pde {
    color: #cc7833 !important;
  }

  /* punctuation.definition.entity */
  .pl-pdi {
    color: #ffe5bb !important;
    font-style: italic !important;
  }

  /* punctuation.definition.italic */
  .pl-pds {
    color: #a5c261 !important;
  }

  /* punctuation.definition.string */
  .pl-pdv {
    color: #b83426 !important;
  }

  /* punctuation.definition.variable */
  .pl-pse {
    color: #cc7833 !important;
  }

  /* punctuation.section.embedded */
  .pl-pse .pl-s2 {
    color: #cc7833 !important;
  }

  /* punctuation.section.embedded source */
  .pl-s {
    color: #cc7833 !important;
  }

  /* storage */
  .pl-s1 {
    color: #a5c261 !important;
  }

  /* string */
  .pl-s2 {
    color: #fff !important;
  }

  /* source */
  .pl-mp .pl-s3 {
    color: #cc7833 !important;
  }

  /* support */
  .pl-s3 {
    color: #ffe5bb !important;
  }

  /* support */
  .pl-sc {
    color: #ffc66d !important;
  }

  /* support.class */
  .pl-scp {
    color: #6c99bb !important;
  }

  /* support.constant.property-value */
  .pl-sf {
    color: #ffc66d !important;
  }

  /* support.function */
  .pl-smc {
    color: #b83426 !important;
  }

  /* storage.modifier.c */
  .pl-smi {
    color: #ffc66d !important;
  }

  /* storage.modifier.import */
  .pl-smp {
    color: #ffc66d !important;
  }

  /* storage.modifier.package */
  .pl-sok {
    color: #ffe5bb !important;
  }

  /* support.other.keyword */
  .pl-sol {
    color: #a5c261 !important;
  }

  /* string.other.link */
  .pl-som {
    color: #b83426 !important;
  }

  /* support.other.module */
  .pl-sr {
    color: #b83426 !important;
  }

  /* string.regexp */
  .pl-sra {
    color: #cc3 !important;
  }

  /* string.regexp string.regexp.arbitrary-repetition */
  .pl-src {
    color: #cc3 !important;
  }

  /* string.regexp.character-class */
  .pl-sre {
    color: #cc3 !important;
  }

  /* string.regexp source.ruby.embedded */
  .pl-st {
    color: #cc7833 !important;
  }

  /* support.type */
  .pl-stj {
    color: #fff !important;
  }

  /* storage.type.java */
  .pl-stp {
    color: #cc7833 !important;
  }

  /* support.type.property-name */
  .pl-sv {
    color: #cc7833 !important;
  }

  /* support.variable */
  .pl-v {
    color: #cc7833 !important;
  }

  /* variable */
  .pl-vi {
    color: #cc7833 !important;
  }

  /* variable.interpolation */
  .pl-vo {
    color: #fff !important;
  }

  /* variable.other */
  .pl-vpf {
    color: #cc7833 !important;
  }

  /* variable.parameter.function */
  /* diff - example: https://gist.github.com/silverwind/904159f1e71e2e626375 */
  .pl-mi1 {
    color: #a5c261 !important;
    background: rgba(0, 64, 0, .5) !important;
  }

  /* markup.inserted */
  .pl-mdht {
    color: #a5c261 !important;
    background: rgba(0, 64, 0, .5) !important;
  }

  /* meta.diff.header.to-file */
  .pl-md {
    color: #b83426 !important;
    background: rgba(64, 0, 0, .5) !important;
  }

  /* markup.deleted */
  .pl-mdhf {
    color: #b83426 !important;
    background: rgba(64, 0, 0, .5) !important;
  }

  /* meta.diff.header.from-file */
  .pl-mdr {
    color: #cc7833 !important;
    font-weight: normal !important;
  }

  /* meta.diff.range */
  .pl-mdh {
    color: #ffc66d !important;
    font-weight: normal !important;
  }

  /* meta.diff.header */
  .pl-mdi {
    color: #ffc66d !important;
    font-weight: normal !important;
  }

  /* meta.diff.index */
  /* todo: fix unstyled classes below */
  .pl-bu, /* invalid.broken, invalid.deprecated, invalid.unimplemented, message.error, brackethighlighter.unmatched, sublimelinter.mark.error */
  .pl-ii {
    background-color: #b83426 !important;
    color: #fff !important;
  }

  /* invalid.illegal */
  .pl-mo {
    color: #ffc66d !important;
  }

  /* meta.output */
  .pl-mri {
    color: #cc7833 !important;
  }

  /* markup.raw.inline */
  .pl-ms1 {
    background-color: #ffc66d !important;
  }

  /* meta.separator */
  .pl-va {
    color: #cc7833 !important;
  }

  /* variable.assignment */
  .pl-vpu {
    color: #cc7833 !important;
  }

  /* variable.parameter.url */
  .pl-entl {
    color: #ffc66d !important;
  }

  /* entity.name.tag.label */
  .pl-corl, .highlight .pl-corl span.x {
    color: #cc7833 !important;
    text-decoration: underline !important;
  }

  /* links */
  /* language tweaks */
  .highlight-text-html-basic .pl-ent {
    color: #cc7833 !important;
  }

  /* entity.name.tag */
  .pl-token.active, .pl-token:hover {
    background: #ffc66f !important;
    color: #323232 !important;
  }
}