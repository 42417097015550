
html {
  background-color: $dark-bg-color;
}

body, input, textarea, select, button {
  background-color: $dark-bg-color;
}

html, body, input, textarea, select, button {
  border-color: $dark-border-color;
  color: $dark-text-color;
}

::placeholder {
  color: #e6d1aa;
}

::selection, ::-moz-selection {
  background-color: #736650;
  color: #fffffb;
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  background-color: $dark-input-bg-color !important;
  color: $dark-text-color !important;
}

body {
  background-image: initial;
  background-color: $dark-body-bg-color;
  color: $dark-body-color;
}

body.draft {
  background: repeating-linear-gradient(
                  45deg,
                  rgba(0, 0, 0, 0.55),
                  rgba(0, 0, 0, 0.55) 1rem,
                  rgba(0, 0, 0, 0.4) 1rem,
                  rgba(0, 0, 0, 0.4) 2rem
  );

  & div.content.container article::before {
    color: $draft-red;
    background: $dark-body-bg-color;
    border-color: $draft-red;
  }
}

blockquote {
  color: $dark-quote-color;
  background-color: $dark-blockquote-bg-color;
  border-left-color: $dark-blockquote-border-color;;
}

kbd {
  border: 1px solid rgb(70, 69, 67);
  background-color: rgb(39, 40, 40);
  color: rgb(212, 207, 197);
  box-shadow: rgba(13, 13, 13, 0.2) 0px 0px 0px 0px, rgb(36, 37, 37) 0px 0px 0px 2px inset;
  text-shadow: rgb(36, 37, 37) 0px 1px 0px
}

.menuseq {
  color: rgba(250, 247, 217, 0.8);

  .menu {
    background-color: rgb(39, 40, 40);
    color: rgba(250, 247, 217, 0.8);
    border: rgba(250, 247, 217, 0.8) solid 1px;
  }
}

.button {
  color: rgba(250, 247, 217, 0.8);
}

a {
  //border-bottom: 1px dotted;
  color: $link-color;
  text-decoration: none;
  word-wrap: break-word;

  &.sidebar-nav-item {
    &:hover {
      @include link-no-decoration();
    }

    &:focus {
      @include link-no-decoration();
    }
  }

  &:focus {
    @include link-hover();

    &.tag {
      @include link-no-decoration();
    }
  }

  &:hover {
    @include link-hover();

    &.tag {
      @include link-no-decoration();
    }
  }

  &.tag {
    @include link-no-decoration();
  }

  &.read-more-symbol {
    text-decoration: none;
    @include link-no-decoration();
  }
}

.sidebar {
  background-color: #46392e;

  a {
    @include link-no-decoration();
  }

  .social a {
    @include link-no-decoration();
  }
}

.landing-more a {
  @include link-no-decoration();
}

// fix .navigation
.navigation a {
  &:hover, &:focus {
    border: 2px solid $navigation-color;
  }
}

/* image display */
.content .post p {
  img, object, svg {
    background-color: rgb(183, 158, 116);
  }

  img {
    opacity: .65;
    transition: opacity .5s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }
}

/* scrollbars */
* {
  /* For Internet Explorer */
  scrollbar-face-color: $scrollbar-color;
  /* The emerging W3C standard
     that is currently Firefox-only */
  scrollbar-color: $scrollbar-color transparent;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0.4rem 0.4rem $scrollbar-color;
}


/* taxonomy navigation */
ul.tags,
ul.taxonomies,
ul.posts {

  li {
    &.taxonomy-item a::before,
    &.blog-item a::before {
      color: $link-color;

      &:hover {
        color: $link-hover-color;
      }
    }

    .list__title--small {
      a {
        &:focus {
          @include link-hover();
        }

        &:hover {
          @include link-hover();
        }
      }
    }
  }
}

.character-container {
  background-color: unset;

  .inactive {
    //color: $gray-3;
  }
}


hr {
  background-color: rgb(183, 158, 116);
}
