.badge-category,
a.badge-category {
  color: #fff !important;
  background-color: #0088cc;

  &:hover, &:focus {
    color: #0088cc !important;
  }
}

.badge-tag,
a.badge-tag {
  color: $dark-body-color !important;
  background-color: unset;
  border: 1px solid $tag-color;

  &:hover, &:focus {
    color: $dark-hover-link-color !important;
    border: $dark-hover-link-color solid 1px;
  }
}

